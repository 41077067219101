import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { BehaviorSubject, Subscription } from "rxjs";
import { Incident } from "src/app/_share/_models/incident";
import { IncidentService } from "src/app/_share/_services/incident.service";

import { Leave } from "src/app/_share/_models/leave";
import { LeaveService } from "src/app/_share/_services/leave.service";

@Component({
  selector: "app-view-leave",
  templateUrl: "./view-leave.component.html",
  styleUrls: ["./view-leave.component.scss"],
})
export class ViewLeaveComponent implements OnInit {
  // employeeIncidentHistoryQuery$: Subscription | undefined;

  // employeeIncidentHistory: any[] = [];
  // employeeIncidenthistory$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
  //   []
  // );

  employeeLeaveHistoryQuery$: Subscription | undefined;
  employeeLeaveHistory: any[] = [];
  employeeLeavehistory$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );
  loadingHistory: boolean = false;
  leave: any;
  // employee: any;

  constructor(
    private config: DynamicDialogConfig,
    private leaveService: LeaveService // private incidentService: IncidentService
  ) {}

  ngOnInit(): void {
    this.leave = this.config.data;
    this.getEmployeeIncidentHistory();
    // this.employee = this.config.data;
    // this.getEmployeeIncidentHistory();
  }

  getEmployeeIncidentHistory() {
    // Get employee incident history
    this.loadingHistory = true;
    this.employeeLeaveHistoryQuery$ = this.leaveService
      .getLeaveByEmployeeId(this.leave.id)
      .subscribe((leaves: Leave[]) => {
        this.employeeLeaveHistory = leaves;
        this.employeeLeavehistory$.next(leaves);
        this.loadingHistory = false;
      });
    // this.employeeIncidentHistoryQuery$ = this.incidentService
    //   .getIncidentsByEmployeeId(this.employee.id)
    //   .subscribe((incidents: Incident[]) => {
    //     this.employeeIncidentHistory = incidents;
    //     this.employeeIncidenthistory$.next(incidents);
    //     this.loadingHistory = false;
    //   });
  }

  ngOnDestroy(): void {
    // if (this.employeeIncidentHistoryQuery$) {
    //   this.employeeIncidentHistoryQuery$.unsubscribe();
    // }
    if (this.employeeLeaveHistoryQuery$) {
      this.employeeLeaveHistoryQuery$.unsubscribe();
    }
  }

  getLeaveStatusSeverity(value: string): string {
    switch (value) {
      case "open":
        return "success";
      case "not guilty":
      case "acknowledged verbal warning":
      case "acknowledged written warning":
      case "acknowledged final written warning":
      case "acknowledge pending hearing":
      case "hearing scheduled":
        return "info";
      case "verbal warning issued":
      case "written warning issued":
      case "final written warning issued":
        return "warning";
      case "pending hearing":
        return "danger";
      default:
        return "info";
    }
  }
}
