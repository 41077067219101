<div *ngIf="this.Incidents$ | async; else loadedContent">
  <div class="flex p-3 flex-column">
    <div class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8">
      <div class="field col-12">
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
      </div>
      <div class="field col-12">
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
      </div>
    </div>
  </div>
</div>

<ng-template #loadedContent>
  <div class="border-round">
    <!-- <div class="font-medium text-3xl text-900 mb-3">Applicant Profile</div> -->
    <!-- <div class="text-500 mb-5">
      Egestas sed tempus urna et pharetra pharetra massa massa ultricies.
    </div> -->
    <div class="grid grid-nogutter">
      <!-- border-top-1 surface-border pt-2 -->
      <div class="col-12 pt-3 px-3">
        <div
          class="tex-700 font-medium mb-2 text-xl surface-border border-bottom-1"
        >
          Employee
        </div>
      </div>
      <div class="col-12 md:col-2 p-3">
        <div class="text-500 font-medium mb-2">Employee Code</div>
        <div class="text-900 text-3xl">
          {{ incident.employee.employee_code }}
        </div>
      </div>
      <div class="col-12 md:col-5 p-3 block">
        <div class="text-500 font-medium mb-2">Employee First Name</div>
        <div class="text-900">{{ incident.employee.first_name }}</div>
      </div>
      <div class="col-12 md:col-5 p-3">
        <div class="text-500 font-medium mb-2">Employee Last Name</div>
        <div class="text-900">{{ incident.employee.last_name }}</div>
      </div>
      <!-- <div class="col-12 md:col-3 p-3">
        <div class="text-500 font-medium mb-2">Email</div>
        <div class="text-900">{{ incident.employee.email }}</div>
      </div>
      <div class="col-12 md:col-3 p-3">
        <div class="text-500 font-medium mb-2">Phone</div>
        <div class="text-900">{{ incident.employee.phone_number }}</div>
      </div>
      <div class="col-12 md:col-3 p-3">
        <div class="text-500 font-medium mb-2">Department</div>
        <div class="text-900">{{ incident.employee.department }}</div>
      </div>

      <div class="col-12 md:col-6 p-3">
        <div class="text-500 font-medium mb-2">Email</div>
        <div class="text-900">{{ incident.employee.email }}</div>
      </div> -->
      <div class="col-12 p-3">
        <div class="text-500 font-medium mb-2">Employee Incident History</div>
        <div class="text-900 line-height-3">
          <p-timeline [value]="employeeIncidentHistory" class="p-1">
            <ng-template pTemplate="content" let-historyItem>
              <small class="p-text-secondary">{{
                historyItem.date | date : "YYYY-MM-dd"
              }}</small>
            </ng-template>
            <ng-template pTemplate="opposite" let-historyItem>
              {{ historyItem.category | uppercase }}
            </ng-template>
          </p-timeline>
        </div>
      </div>

      <!-- transgression section  -->
      <div class="col-12 pt-3 px-3">
        <div
          class="tex-700 font-medium mb-2 text-xl surface-border border-bottom-1"
        >
          Transgression
        </div>
      </div>
      <div class="col-12 md:col-3 p-3">
        <div class="text-500 font-medium mb-2">Category</div>
        <div class="text-900">
          <p-badge
            [value]="incident.transgression.category | uppercase"
            severity="info"
            class="w-24"
          ></p-badge>
        </div>
      </div>

      <div class="col-12 md:col-9 p-3">
        <div class="text-500 font-medium mb-2">Description</div>
        <div class="text-900">
          {{ incident.transgression.offence }}
        </div>
      </div>
      <!-- incident section  -->
      <div class="col-12 pt-3 px-3">
        <div
          class="tex-700 font-medium mb-2 text-xl surface-border border-bottom-1"
        >
          Incident Details
        </div>
      </div>
      <div class="col-12 md:col-3 p-3">
        <div class="text-500 font-medium mb-2">Incident Status</div>
        <div class="text-900 line-height-3">
          <p-badge
            [value]="incident.incident_status || '' | uppercase"
            [severity]="
              incident.incident_status === 'concluded' ? 'warning' : 'success'
            "
            class="w-24"
          ></p-badge>
        </div>
      </div>
      <div class="col-12 md:col-3 p-3">
        <div class="text-500 font-medium mb-2">Action Taken</div>
        <div class="text-900 line-height-3">
          <p-badge
            [value]="incident.action_taken || 'N/A'"
            severity="danger"
            class="w-24"
          ></p-badge>
        </div>
      </div>

      <div class="col-12 md:col-6 p-3">
        <div class="text-500 font-medium mb-2">Incident Progression</div>
        <div class="text-900 line-height-3">
          <p-timeline [value]="incidentActions" class="p-1">
            <ng-template pTemplate="content" let-action>
              <small class="p-text-secondary">{{
                action.action_date | date : "YYYY-MM-dd"
              }}</small>
            </ng-template>
            <ng-template pTemplate="opposite" let-action>
              {{ action.action_type }}
            </ng-template>
          </p-timeline>
        </div>
      </div>
      <div class="col-12 md:col-6 p-3">
        <div class="text-500 font-medium mb-2">Incident Date</div>
        <div class="text-900 line-height-3">
          {{ incident.date || "" | date : "YYYY-MM-dd" }}
        </div>
      </div>
      <div class="col-12 p-3">
        <div class="text-500 font-medium mb-2">Incident Description</div>
        <div class="text-900 line-height-3">{{ incident.description }}</div>
      </div>
      <!-- Evidence files -->
      <div class="col-4 p-3">
        <div class="text-700 font-medium text-lg mb-3">Evidence Files</div>
        <ng-container
          *ngIf="evidencesFiles.length === 0; else evidenceFilesList"
        >
          <div
            class="text-500 font-medium border-top-1 surface-border p-3 text-center"
          >
            No files attached
          </div>
        </ng-container>
        <ng-template #evidenceFilesList>
          <div
            class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
            *ngFor="let file of evidencesFiles"
          >
            <div class="flex align-items-center">
              <span class="block pi pi-file mr-2"></span>
              <div>
                <div class="text-900 mr-2">{{ file.original_filename }}</div>
                <div class="text-500 text-xs">
                  uploaded {{ file.date_uploaded | dateAsAgo }}
                </div>
              </div>
            </div>
            <a
              href="javascript:void(0);"
              pButton
              pRipple
              icon="pi pi-download"
              class="p-button-outlined mt-2 md:mt-0"
              style="text-decoration: none"
              (click)="
                downloadFile(
                  file.unique_file_reference,
                  file.original_filename
                );
                $event.stopPropagation()
              "
            ></a>
          </div>
        </ng-template>
      </div>

      <!-- Admin files -->
      <div class="col-4 p-3">
        <div class="text-700 font-medium text-lg mb-3">Admin Files</div>
        <ng-container *ngIf="adminFiles.length === 0; else adminFilesList">
          <div
            class="text-500 font-medium border-top-1 surface-border p-3 text-center"
          >
            No files attached
          </div>
        </ng-container>
        <ng-template #adminFilesList>
          <div
            class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
            *ngFor="let file of adminFiles"
          >
            <div class="flex align-items-center">
              <span class="block pi pi-file mr-2"></span>
              <div>
                <div class="text-900 mr-2">{{ file.original_filename }}</div>
                <div class="text-500 text-xs">
                  uploaded {{ file.date_uploaded | dateAsAgo }}
                </div>
              </div>
            </div>
            <a
              href="javascript:void(0);"
              pButton
              pRipple
              icon="pi pi-download"
              class="p-button-outlined mt-2 md:mt-0"
              style="text-decoration: none"
              (click)="
                downloadFile(
                  file.unique_file_reference,
                  file.original_filename
                );
                $event.stopPropagation()
              "
            ></a>
          </div>
        </ng-template>
      </div>

      <!-- Generated files -->
      <div class="col-4 p-3">
        <div class="text-700 font-medium text-lg mb-3">Generated Files</div>
        <ng-container
          *ngIf="generatedFiles.length === 0; else generatedFilesList"
        >
          <div
            class="text-500 font-medium border-top-1 surface-border p-3 text-center"
          >
            No files attached
          </div>
        </ng-container>
        <ng-template #generatedFilesList>
          <div
            class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
            *ngFor="let file of generatedFiles"
          >
            <div class="flex align-items-center">
              <span class="block pi pi-file mr-2"></span>
              <div>
                <div class="text-900 mr-2">{{ file.original_filename }}</div>
                <div class="text-500 text-xs">
                  uploaded {{ file.date_uploaded | dateAsAgo }}
                </div>
              </div>
            </div>
            <a
              href="javascript:void(0);"
              pButton
              pRipple
              icon="pi pi-download"
              class="p-button-outlined mt-2 md:mt-0"
              style="text-decoration: none"
              (click)="
                downloadGeneratedFile(file.id, incident.id);
                $event.stopPropagation()
              "
            ></a>
          </div>
        </ng-template>
      </div>
    </div>
    <!-- Actions Block -->
    <div class="col-12 pt-3 px-3">
      <!-- lg:col-6 mt-4 -->
      <!-- <div class="surface-card p-4 shadow-2 border-round"> -->
      <div
        class="tex-700 font-medium mb-2 text-xl surface-border border-bottom-1"
      >
        Action(s)
      </div>
    </div>

    <!-- actions -->

    <div class="col-12 p-3">
      <div class="formgrid p-fluid">
        <div class="field mb-4 col-12 lg:col-12">
          <div *ngIf="incident?.incident_status === 'written-warning-issued'">
            <!-- todo: please improve logic here, download warning file shouldn't be always visible etc -->
          </div>
        </div>
      </div>

      <div class="formgrid p-fluid" [formGroup]="incidentForm">
        <!----ngif for hiding-->
        <div *ngIf="incident?.incident_status != 'open'">
          <!-- File Upload Field -->
          <div class="field mb-4 col-12 lg:col-12">
            <label for="file_id">File</label>
            <p-fileUpload
              name="file[]"
              #fubadvanced
              [multiple]="true"
              [customUpload]="true"
              accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              [maxFileSize]="1000000"
              (uploadHandler)="onFileUpload($event, fubadvanced)"
            >
              <ng-template pTemplate="content">
                <div *ngIf="uploadedFiles.length">
                  <div
                    class="flex p-1 flex-column"
                    *ngFor="let file of uploadedFiles"
                  >
                    <div class="flex align-items-center">
                      <span class="block pi pi-file mr-2"></span>
                      <span class="text-900"
                        >{{ file.original_filename }} ({{
                          file.file_size
                        }}
                        bytes)</span
                      >
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-fileUpload>
          </div>
          <!-- overriding -->
          <!-- 'hearing-scheduled' -->
          <div
            *ngIf="
              incident.incident_status &&
              [
                'written-warning-issued',
                'final-written-warning-issued',
                'pending-hearing',
               
              ].includes(incident.incident_status)
            "
          >
            <div class="field">
              <label for="overrideStatus" class="text-500 font-medium mb-2"
                >Override Action</label
              >
              <p-dropdown
                formControlName="overrideStatus"
                [options]="getFilteredOverrideStatusOptions()"
                optionLabel="label"
                optionValue="value"
                placeholder="Select override action"
                class="w-full"
              ></p-dropdown>
            </div>
            <div class="field mt-3">
              <button
                pButton
                label="Override Action Taken"
                class="p-button-warning w-full"
                (click)="applyOverride()"
              ></button>
            </div>
          </div>
          <button
            *ngIf="
              incident.incident_status &&
              ![
               
                'acknowledged-verbal-warning',
                'acknowledged-written-warning',
                'acknowledged-final-written-warning',
                'hearing-scheduled'
              ].includes(incident.incident_status)
            "
            (click)="submitAction()"
            pButton
            label="Acknowledge incident"
          ></button>

          <div class="field mt-2">
            <button
              *ngIf="
                incident.incident_status &&
                !['verbal-warning-issued', 'hearing-scheduled'].includes(
                  incident.incident_status
                )
              "
              pButton
              label="Generate Warning Document"
              class="p-button-outlined w-full"
              icon="pi pi-download"
              (click)="downloadWarningDocument(incident.id)"
            ></button>
          </div>
        </div>
        <!----ngif for hiding-->
        <div *ngIf="incident?.incident_status == 'open'">
          <div class="field">
            <label for="incidentResolution" class="text-500 font-medium mb-2"
              >Resolution</label
            >
            <p-dropdown
              formControlName="resolution"
              [options]="resolutionOptions"
              optionLabel="label"
              optionValue="value"
              placeholder="Investigation Verdict"
              class="w-full"
            ></p-dropdown>
            <div
              *ngIf="
                incidentForm.get('resolution')?.invalid &&
                (incidentForm.get('resolution')?.touched ||
                  incidentForm.get('resolution')?.dirty)
              "
              class="p-error mt-1"
            >
              <small><strong>Resolution</strong> is required</small>
            </div>
          </div>
          <div class="field mt-3">
            <button
              pButton
              label="Conclude Incident Investigation"
              class="p-button-success w-full"
              (click)="concludeIncident()"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
