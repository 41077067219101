import { Component, OnDestroy, OnInit } from "@angular/core";

import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ConfirmationService, MessageService } from "primeng/api";
import { LeaveService } from "src/app/_share/_services/leave.service";
import { Leave } from "src/app/_share/_models/leave";
import { CreateLeaveComponent } from "../create-leave/create-leave.component";
import { ViewLeaveComponent } from "../view-leave/view-leave.component";
import { EmployeeService } from "src/app/_share/_services/employee.service";

@Component({
  selector: "app-leaves",
  templateUrl: "./leaves.component.html",
  styleUrls: ["./leaves.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class LeavesComponent implements OnInit, OnDestroy {
  constructor(
    public dialogService: DialogService,
    public leaveService: LeaveService,
    public employeeService: EmployeeService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}
  employees: any[] = [];
  Leaves$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  ref: DynamicDialogRef | undefined;
  leaves: any[] = [];
  leavesObservable$: any;
  private subscriptions: Subscription = new Subscription();

  ngOnInit(): void {
    setTimeout(() => {
      this.Leaves$.next(false);
      this.loadEmployees();
    }, 3000);
    this.subscriptions.add(
      this.leaveService.getLeaves().subscribe((leaves) => {
        this.leaves = leaves;
      })
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  selectedRowCMObservable$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  getLeavesOptionsList() {
    this.subscriptions.add(
      this.leaveService.getLeaves().subscribe((leaves: any[]) => {
        this.leaves = leaves;
      })
    );
  }

  columns = [
    { field: "leave_status", header: "Status" },
    { field: "employee_id", header: "Employee Name" },
    { field: "leave_type", header: "Leave Type" },
    { field: "sick_leave_amount", header: "Sick Leave Amount" },
    { field: "annual_leave_amount", header: "Annual Leave Amount" },
    { field: "maternity_leave_amount", header: "Maternity Leave Amount" },
    { field: "start_period", header: "Start Date" },
    { field: "end_period", header: "End Date" },
  ];

  onGlobalSearch(event: any) {
    const searchValue = event.target.value;
    if (searchValue.trim()) {
      // Filter the  array based on the global search query
      this.leaves = this.filterLeaves(searchValue);
    } else {
      // If search is cleared, load the full list
      this.getLeavesOptionsList();
    }
  }
  loadEmployees() {
    this.employeeService.getEmployees().subscribe((employees) => {
      this.employees = employees;
    });
  }
  getEmployeeNameById(id: number): string {
    const employee = this.employees.find((emp: any) => emp.id === id);
    return employee
      ? `${employee.employee_code}  ${employee.first_name} ${employee.last_name}`
      : "loading...";
  }

  getLeaveStatusSeverity(value: string): string {
    switch (value.toLowerCase()) {
      case "suspended":
      case "unpaid suspension":
        return "warning";
      case "active":
        return "success";
      case "terminated":
        return "danger";
      default:
        return "info";
    }
  }
  filterLeaves(query: string): any[] {
    return this.leaves.filter((leave) =>
      Object.values(leave).some((value) =>
        String(value).toLowerCase().includes(query.toLowerCase())
      )
    );
  }

  showViewLeave(rowData: any) {
    this.ref = this.dialogService.open(ViewLeaveComponent, {
      data: rowData,

      header: "Leave Details",
      width: "90%",
      height: "70%",
      closeOnEscape: true,
    });
  }

  showAdd() {
    this.ref = this.dialogService.open(CreateLeaveComponent, {
      data: {
        leaveData: null,
        listofleaves: this.leaveService.getLeaves(),
      },
      header: "Add Leave",
      width: "65%",
      height: "55%",
      closeOnEscape: true,
    });

    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.getLeavesOptionsList();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Leave successfully added",
        });
      } else {
        this.messageService.add({
          severity: "info",
          summary: "Cancelled",
          detail: "Adding leave was cancelled",
        });
      }
    });
  }

  // Edit function
  editLeave(rowData: any) {
    console.log("row", rowData);
    this.ref = this.dialogService.open(CreateLeaveComponent, {
      data: {
        formdata: rowData,
      },
      header: "Edit Leave",
      width: "70%",
      height: "55%",
      closeOnEscape: true,
    });
    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.getLeavesOptionsList();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Leave updated successfully",
        });
      } else {
        this.messageService.add({
          severity: "info",
          summary: "Cancelled",
          detail: "Editing leave was cancelled",
        });
      }
    });
  }
  deleteLeave(rowData: any) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete this leave?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        this.leaveService.deleteLeave(rowData.id).subscribe(() => {
          this.getLeavesOptionsList();
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Leave deleted successfully",
          });
        });
      },
      reject: () => {
        this.messageService.add({
          severity: "info",
          summary: "Rejected",
          detail: "Leave was not deleted",
        });
      },
    });
  }
}
