import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, map, switchMap, tap } from "rxjs";

import { Leave } from "../_models/leave";
import { ApiService } from "src/app/_core/_services/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class LeaveService {
  private leaves: Leave[] = [];
  private leaves$ = new BehaviorSubject<Leave[]>([]);

  constructor(public api: ApiService, private http: HttpClient) {}

  getLeaves(): Observable<Leave[]> {
    return this.api.getAPI<Leave[]>("leaves").pipe(
      map((res) =>
        res.body.sort((a: { id: number }, b: { id: number }) => a.id - b.id)
      ),
      tap((leaves) => this.leaves$.next(leaves))
    );
  }

  getLeaveByEmployeeId(employeeId: number): Observable<Leave[]> {
    return this.api.getAPI<Leave[]>(`leaves/employee/${employeeId}`).pipe(
      map((res) => {
        console.log("Leaves by employee", res.body.data);
        return res.body.data;
      })
    );
  }

  addleave(newLeave: Leave): Observable<any> {
    return this.api.postAPI<Leave>("leaves/create", newLeave).pipe(
      tap((res: any) => {
        console.log("leave added", res);
      }),
      switchMap(() => this.getLeaves())
    );
  }

  updateleave(leaveId: number, leavesData: Leave): Observable<any> {
    return this.api.patchAPI<Leave>("leaves/update", leaveId, leavesData).pipe(
      tap((res: any) => {
        console.log("leave updated", res);
      }),
      switchMap(() => this.getLeaves())
    );
  }

  deleteLeave(leaveId: number): Observable<any> {
    return this.api
      .deleteAPI<any>("leaves/delete", leaveId)
      .pipe(tap(() => this.refreshleaves()));
  }
  refreshleaves(): Observable<Leave[]> {
    return this.getLeaves().pipe(
      tap((leaves) => {
        this.leaves = leaves;
        this.leaves$.next(this.leaves);
      })
    );
  }
  get leavesObservable$(): Observable<Leave[]> {
    return this.leaves$.asObservable();
  }
}
