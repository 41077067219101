<div
  *ngIf="this.createLeaves$ | async; else loadedContent"
  class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8"
>
  <!-- Skeleton loader or loading state -->
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
</div>

<ng-template #loadedContent>
  <form [formGroup]="leaveForm" (ngSubmit)="onAddleaveFormSubmit()">
    <div class="surface-section px-4 py-4 md:px-6 lg:px-8">
      <div class="grid formgrid p-fluid">
        <!-- employees Field -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="employee_id">Employee</label>
          <p-dropdown
            [options]="employees"
            optionLabel="label"
            optionValue="value"
            formControlName="employee_id"
            placeholder="Select an employee"
            [filter]="true"
            filterPlaceholder="Search employees by code or name"
          ></p-dropdown>
          <div
            *ngIf="
              leaveForm.controls['employee_id'].invalid &&
              (leaveForm.controls['employee_id'].dirty ||
                leaveForm.controls['employee_id'].touched)
            "
            class="p-error"
          >
            <small class="p-error block"
              ><strong> Employee</strong> is required</small
            >
          </div>
        </div>
        <!-- leave type Field -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="leave_type">Leave Type</label>
          <input
            formControlName="leave_type"
            type="text"
            class="form-control"
            id="leave_type"
            placeholder="Enter leave type"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              leaveForm.get('leave_type')?.invalid &&
              (leaveForm.get('leave_type')?.touched ||
                leaveForm.get('leave_type')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Leave Type</strong> is required</small
            >
          </div>
        </div>
        <!-- sick leave amount -->
        <div class="field mb-4 col-12 lg:col-4">
          <label for="sick_leave_amount">sick leave amount</label>
          <input
            formControlName="sick_leave_amount"
            type="text"
            class="form-control"
            id="sick_leave_amount"
            placeholder="Enter sick leave amount"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              leaveForm.get('sick_leave_amount')?.invalid &&
              (leaveForm.get('sick_leave_amount')?.touched ||
                leaveForm.get('sick_leave_amount')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>sick leave amount</strong> is required</small
            >
          </div>
        </div>
        <!-- annual_leave_amount -->
        <div class="field mb-4 col-12 lg:col-4">
          <label for="annual_leave_amount">annual leave amount</label>
          <input
            formControlName="annual_leave_amount"
            type="text"
            class="form-control"
            id="annual_leave_amount"
            placeholder="Enter annual leave amount"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              leaveForm.get('annual_leave_amount')?.invalid &&
              (leaveForm.get('annual_leave_amount')?.touched ||
                leaveForm.get('annual_leave_amount')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>annual leave amount</strong> is required</small
            >
          </div>
        </div>
        <!-- maternity_leave_amount -->
        <div class="field mb-4 col-12 lg:col-4">
          <label for="maternity_leave_amount">maternity leave amount</label>
          <input
            formControlName="maternity_leave_amount"
            type="text"
            class="form-control"
            id="maternity_leave_amount"
            placeholder="Enter maternity leave amount"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              leaveForm.get('maternity_leave_amount')?.invalid &&
              (leaveForm.get('maternity_leave_amount')?.touched ||
                leaveForm.get('maternity_leave_amount')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>maternity leave amount</strong> is required</small
            >
          </div>
        </div>
        <!-- start_period Field -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="start_period">start period</label>
          <p-calendar
            [showIcon]="true"
            type="text"
            id="start_period"
            name="start_period"
            class="w-full"
            formControlName="start_period"
            dateFormat="yy-mm-dd"
            [inline]="false"
            dataType="string"
            appendTo="body"
          ></p-calendar>
        </div>
        <!-- end_period Field -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="end_period">end period</label>
          <p-calendar
            [showIcon]="true"
            type="text"
            id="end_period"
            name="end_period"
            class="w-full"
            formControlName="end_period"
            dateFormat="yy-mm-dd"
            [inline]="false"
            dataType="string"
            appendTo="body"
          ></p-calendar>
        </div>
        <div class="field col-12">
          <p-button
            styleClass="bg-blue-400"
            type="submit"
            label="Submit"
            class="w-6"
          ></p-button>
          <!-- [loading]="loading" -->
        </div>
      </div>
    </div>
  </form>
</ng-template>
