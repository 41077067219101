import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject } from "rxjs";
import { Leave } from "src/app/_share/_models/leave";
import { LeaveService } from "src/app/_share/_services/leave.service";
import { EmployeeService } from "src/app/_share/_services/employee.service";

@Component({
  selector: "app-create-leave",
  templateUrl: "./create-leave.component.html",
  styleUrls: ["./create-leave.component.scss"],
})
export class CreateLeaveComponent implements OnInit {
  createLeaves$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  leaveForm: FormGroup = this.formBuilder.group({
    leave_status: ["active"],
    employee_id: ["", Validators.required],
    leave_type: ["", Validators.required],
    sick_leave_amount: ["", Validators.required],
    annual_leave_amount: ["", Validators.required],
    maternity_leave_amount: ["", [Validators.required, Validators.email]],
    start_period: ["", Validators.required],
    end_period: ["", Validators.required],
  });

  loading: boolean = false;
  employees: any[] = [];
  editFormData: Leave | undefined;
  isInEditMode: boolean = false;
  statusOptions: { label: string; value: string }[] = [
    { label: "Active", value: "active" },
    { label: "Terminated", value: "terminated" },
  ];
  editFormGroups: any;
  fb: any;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public leaveService: LeaveService,
    public formBuilder: FormBuilder,
    public employeeService: EmployeeService
  ) {}

  ngOnInit(): void {
    // Simulate loading completion
    setTimeout(() => {
      this.createLeaves$.next(false);
    }, 1000);
    this.updateFormData();
    this.loadEmployees();
  }

  loadEmployees() {
    this.employeeService.getEmployees().subscribe((employees) => {
      this.employees = employees.map((employee: any) => ({
        label: `${employee.employee_code} ${employee.first_name} ${employee.last_name}`,
        value: employee.id,
      }));
    });
  }

  //Updates the form data if in edit mode
  updateFormData() {
    this.editFormData = this.config.data?.formdata;
    if (this.editFormData) {
      if (Array.isArray(this.editFormData)) {
        if (this.editFormData.length > 0) {
          this.editFormData.forEach((desc: any, index: number) => {
            const formGroup = this.fb.group({
              leave_status: desc.leave_status,
              employee_id: desc.employee_id,
              leave_type: desc.leave_type,
              sick_leave_amount: desc.sick_leave_amount,
              annual_leave_amount: desc.annual_leave_amount,
              maternity_leave_amount: desc.maternity_leave_amount,
              start_period: desc.start_period,
              end_period: desc.end_period,
            });
            this.editFormGroups.push(formGroup);
            this.isInEditMode = true;
          });
        }
      } else {
        this.leaveForm.patchValue(this.editFormData);
        this.isInEditMode = true;
      }
    } else {
      this.isInEditMode = false;
    }
  }

  onAddleaveFormSubmit() {
    this.loading = true;
    if (this.leaveForm.invalid) {
      this.leaveForm.markAllAsTouched();
      return;
    }
    if (this.isInEditMode && this.editFormData?.id !== undefined) {
      // called .value on form data after assigning form values to updatedFormData, thus you were trying to extract this.leaveForm.value.value effectively
      this.leaveService
        .updateleave(this.editFormData.id, this.leaveForm.value)
        .subscribe({
          next: (res: any) => {
            console.log("Leave updated successfully", res);
            this.ref.close(this.leaveForm.value);
          },
          error: (err: any) => {
            console.error("Error updating leave", err);
          },
        });
    } else {
      this.leaveService.addleave(this.leaveForm.value).subscribe({
        next: (res: any) => {
          console.log("Leave added successfully", res);
          this.leaveService.refreshleaves();
          this.ref.close(this.leaveForm.value);
        },
        error: (err: any) => {
          console.error("Error adding leave", err);
        },
      });
    }
  }
}
